

















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { OrdersListItemType } from '@/types/orders'

@Component
export default class OrdersCard extends Vue {
  @Prop({ type: Object })
  order!: OrdersListItemType

  get userRole(): string {
    return this.$store.getters['user/user'].role
  }

  get isAdmin(): boolean {
    return this.userRole === 'admin'
  }

  handleShowMemberDetail(): void {
    this.$emit('show-member-info', this.order.UF_USER_ID)
  }

  handleShowOrderDetail(): void {
    this.$emit('show-detail', this.order.ID)
  }
}
