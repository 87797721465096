






































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AccrualsListItemType } from '@/types/accruals'

@Component
export default class AccrualsCard extends Vue {
  @Prop({ type: Object })
  accrual!: AccrualsListItemType

  get userRole(): string {
    return this.$store.getters['user/user'].role
  }

  get isAdmin(): boolean {
    return this.userRole === 'admin'
  }

  handleShowMemberDetail(): void {
    this.$emit('show-member-info', this.accrual.UF_USER_ID)
  }

  handleShowAccrualDetail(): void {
    this.$emit('show-detail', this.accrual.ID)
  }
}
