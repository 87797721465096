











































import { Component, Vue } from 'vue-property-decorator'
import { TableFilterDataType } from '@/types/general'
import InputCommon from '@/components/common/InputCommon.vue'
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'
import SelectCommon from '@/components/common/SelectCommon.vue'

@Component({
  components: { SelectCommon, DatePickerCommon, InputCommon },
})
export default class OrderReportsFilters extends Vue {
  filtersForm = {
    ID: '',
    USER_LOGIN: '',
    VENDOR_NAME: '',
    REPORT_TYPE: null,
    DATE: '',
    STATUS: null,
  }

  typeOptions = [
    { label: 'Полный', value: 'FULL' },
    { label: 'Заказы', value: 'ORDER' },
    { label: 'Начисления', value: 'ACCRUAL' },
    { label: 'Партнерский счет', value: 'ORDER_P' },
  ]

  statusOptions = [
    { label: 'Готов', value: 'finished' },
    { label: 'Новый', value: 'new' },
    { label: 'В процессе', value: 'in_progress' },
  ]

  handleAddFilters(): void {
    const data: TableFilterDataType[] = []
    Object.entries(this.filtersForm).forEach(([key, value]) => {
      if (value) {
        data.push({
          target: key,
          data: value,
        })
        this.filtersForm[key] = ''
      }
    })

    this.$emit('add-filters', data)
  }
}
