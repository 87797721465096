







































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { OrdersListItemType } from '@/types/orders'
import TableColumnHeader from '@/components/common/TableColumnHeader.vue'

@Component({
  components: { TableColumnHeader },
})
export default class OrdersTable extends Vue {
  @Prop({ type: Boolean })
  loading?: boolean

  @Prop({ type: Array })
  orders!: OrdersListItemType[]

  orderDateFilter = ''

  get userRole(): string {
    return this.$store.getters['user/user'].role
  }

  get isShowCurrency(): boolean {
    return this.userRole === 'admin'
  }

  handleShowOrderInfo(row: { ID: number; UF_USER_ID: number }, e): void {
    if (e.property === 'USER_LOGIN') {
      this.$emit('show-member-info', row.UF_USER_ID)
    } else {
      this.$emit('show-order-info', row.ID)
    }
  }

  handleChangeSort(data: object): void {
    this.$emit('change-sort', data)
  }

  handleAddFilter(filter: { data: string; target: string }): void {
    this.$emit('add-filter', { data: filter.data, target: filter.target })
  }
}
