





































import { Component, Vue, Prop } from 'vue-property-decorator'
import { OrderReportsListItemType } from '@/types/orderReports'

@Component
export default class OrderReportsDetail extends Vue {
  @Prop({ type: Object })
  report!: OrderReportsListItemType

  reportTypes = {
    FULL: 'Полный',
    ORDER: 'Заказы',
    ACCRUAL: 'Начисления',
    ORDER_P: 'Партнерский счет',
  }

  reportsStatus = {
    finished: 'Готов',
    new: 'Новый',
    in_progress: 'В процессе',
  }

  handleDownloadReport(id: string): void {
    const link = document.createElement('a')
    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + `/api/file/downloadId/${id}`
    )
    link.setAttribute('download', 'download')
    link.click()
    link.remove()
  }

  showMemberInfo() {
    this.$emit('show-member-info', this.report.VENDOR_ID)
  }
}
