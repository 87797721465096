












































import { Component, Vue } from 'vue-property-decorator'
import { TableFilterDataType } from '@/types/general'
import InputCommon from '@/components/common/InputCommon.vue'
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'

@Component({
  components: { DatePickerCommon, InputCommon },
})
export default class AccrualsFilters extends Vue {
  filtersForm = {
    ID: '',
    UF_EXT_ID: '',
    USER_LOGIN: '',
    UF_ACCRUAL_PAR: '',
    UF_ACCRUAL_PRICE: '',
    UF_DATE_CREATE: '',
    UF_CURRENCY: '',
  }

  handleAddFilters(): void {
    const data: TableFilterDataType[] = []
    Object.entries(this.filtersForm).forEach(([key, value]) => {
      if (value) {
        data.push({
          target: key,
          data: value,
        })
        this.filtersForm[key] = ''
      }
    })

    this.$emit('add-filters', data)
  }
}
