






































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { OrderReportsListItemType } from '@/types/orderReports'
import TableColumnHeader from '@/components/common/TableColumnHeader.vue'

@Component({ components: { TableColumnHeader } })
export default class OrderReportsTable extends Vue {
  @Prop({ type: Boolean })
  loading!: boolean

  @Prop({ type: Array })
  reports!: OrderReportsListItemType[]

  reportTypes = {
    FULL: 'Полный',
    ORDER: 'Заказы',
    ACCRUAL: 'Начисления',
    ORDER_P: 'Партнерский счет',
  }

  reportsStatus = {
    finished: 'Готов',
    new: 'Новый',
    in_progress: 'В процессе',
  }

  idFilter = ''
  userFilter = ''
  dateCreateFilter = ''
  vendorFilter = ''
  typeFilter = null
  statusFilter = null

  typeOptions = [
    { label: 'Полный', value: 'FULL' },
    { label: 'Заказы', value: 'ORDER' },
    { label: 'Начисления', value: 'ACCRUAL' },
    { label: 'Партнерский счет', value: 'ORDER_P' },
  ]

  statusOptions = [
    { label: 'Готов', value: 'finished' },
    { label: 'Новый', value: 'new' },
    { label: 'В процессе', value: 'in_progress' },
  ]

  get userRole() {
    return this.$store.getters['user/user'].role
  }

  handleDownloadReport(id: string): void {
    const link = document.createElement('a')
    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + `/api/file/downloadId/${id}`
    )
    link.setAttribute('download', 'download')
    link.click()
    link.remove()
  }

  handleShowReportInfo(row: { ID: number; VENDOR_ID: number }, e): void {
    if (e.property === 'USER_LOGIN') {
      this.$emit('show-member-info', row.VENDOR_ID)
    } else {
      this.$emit('show-report-info', row.ID)
    }
  }

  handleChangeSort(data: object): void {
    this.$emit('change-sort', data)
  }

  handleAddFilter(filter: { data: string; target: string }): void {
    this.$emit('add-filter', { data: filter.data, target: filter.target })
  }
}
