



























































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import EllipseCommon from '@/components/common/EllipseCommon.vue'
import Info from '@/components/common/Info.vue'
import { OrdersListItemType } from '@/types/orders'
import OrdersTable from '@/components/orders/OrdersTable.vue'
import MembersDetail from '@/components/members/MembersDetail.vue'
import {
  TableExportType,
  TableFilterType,
  TableOrderType,
} from '@/types/general'
import Drawer from '@/components/common/Drawer.vue'
import OrdersDetail from '@/components/orders/OrdersDetail.vue'
import FilterTags from '@/components/common/FilterTags.vue'
import AccrualsTable from '@/components/accrual/AccrualsTable.vue'
import { AccrualsListItemType } from '@/types/accruals'
import AccrualsDetail from '@/components/accrual/AccrualsDetail.vue'
import { OrderReportsListItemType } from '@/types/orderReports'
import OrderReportsTable from '@/components/order_reports/OrderReportsTable.vue'
import OrderReportsDetail from '@/components/order_reports/OrderReportsDetail.vue'
import ReportsCreateForm from '@/components/order_reports/ReportsCreateForm.vue'
import DialogInfo from '@/components/dialogs/DialogInfo.vue'
import TabsCommon from '@/components/common/TabsCommon.vue'
import OrdersCard from '@/components/mobile/orders/OrdersCard.vue'
import OrdersFilters from '@/components/mobile/orders/OrdersFilters.vue'
import AccrualsCard from '@/components/mobile/accrual/AccrualsCard.vue'
import AccrualsFilters from '@/components/mobile/accrual/AccrualsFilters.vue'
import OrderReportsCard from '@/components/mobile/order_reports/OrderReportsCard.vue'
import OrderReportsFilters from '@/components/mobile/order_reports/OrderReportsFilters.vue'
import { Message } from 'element-ui'
import { MembersListItemType } from '@/types/members'

@Component({
  components: {
    MembersDetail,
    OrderReportsFilters,
    OrderReportsCard,
    AccrualsFilters,
    AccrualsCard,
    OrdersFilters,
    OrdersCard,
    TabsCommon,
    DialogInfo,
    ReportsCreateForm,
    OrderReportsDetail,
    OrderReportsTable,
    AccrualsDetail,
    AccrualsTable,
    FilterTags,
    OrdersDetail,
    Drawer,
    OrdersTable,
    Info,
    EllipseCommon,
  },
})
export default class Orders extends Vue {
  page = 1

  loading = false
  loadingInfo = false
  showMemberInfo = false
  isAnimationEnd = false

  filters: TableFilterType[] = []
  showFilters = false

  ordersFilterNames = {
    ID: 'Id',
    UF_EXT_ID: 'Номер заказа',
    USER_LOGIN: 'Пользователь',
    UF_ORDER_TOTAL: 'Стоимость',
    UF_DATE_CREATE: 'Дата заказа',
    UF_CURRENCY: 'Валюта',
  }
  accrualsFilterNames = {
    ID: 'Id',
    UF_EXT_ID: 'Номер начисления',
    USER_LOGIN: 'Пользователь',
    UF_ACCRUAL_PAR: 'Номинал',
    UF_ACCRUAL_PRICE: 'Стоимость',
    UF_DATE_CREATE: 'Дата заказа',
    UF_CURRENCY: 'Валюта',
  }
  reportsFilterNames = {
    ID: 'Id',
    USER_LOGIN: 'Пользователь',
    DATE: 'Дата заказа',
    VENDOR_NAME: 'Вендор',
    REPORT_TYPE: 'Тип',
    STATUS: 'Статус',
  }

  order: TableOrderType | Record<string, any> = {}

  tabs = ['Заказы призов', 'Пополнение карт', 'Отчеты по заказам']
  selectedTab = 'Заказы призов'

  showOrderDetail = false
  showAccrualDetail = false
  showOrderReportsDetail = false

  showAddReportsForm = false
  addReportSuccess = false
  createReportsError = false

  //Заказы призов
  get ordersList(): OrdersListItemType[] {
    return this.$store.getters['orders/ordersList']
  }

  get ordersDetail(): OrdersListItemType {
    return this.$store.getters['orders/ordersDetail']
  }

  get ordersTotal(): string {
    return this.$store.getters['orders/ordersTotal']
  }

  get selectedOrders(): string {
    return this.$store.getters['orders/selectedOrders']
  }

  get ordersExport(): TableExportType {
    return this.$store.getters['orders/exportOrders']
  }

  //Пополнение карт
  get accrualsList(): AccrualsListItemType[] {
    return this.$store.getters['accruals/accrualsList']
  }

  get accrualsDetail(): AccrualsListItemType {
    return this.$store.getters['accruals/accrualsDetail']
  }

  get accrualsTotal(): string {
    return this.$store.getters['accruals/accrualsTotal']
  }

  get selectedAccruals(): string {
    return this.$store.getters['accruals/selectedAccruals']
  }

  get accrualsExport(): TableExportType {
    return this.$store.getters['accruals/exportAccruals']
  }

  //Отчеты по заказам
  get orderReportsList(): OrderReportsListItemType[] {
    return this.$store.getters['orderReports/orderReportsList']
  }

  get orderReportsDetail(): OrderReportsListItemType {
    return this.$store.getters['orderReports/orderReportsDetail']
  }

  get orderReportsTotal(): string {
    return this.$store.getters['orderReports/orderReportsTotal']
  }

  get selectedOrderReports(): string {
    return this.$store.getters['orderReports/selectedOrderReports']
  }

  get orderReportsExport(): TableExportType {
    return this.$store.getters['orderReports/exportOrderReports']
  }

  get tableFilters() {
    const params = {}

    this.filters.forEach((data: { target: string; data: string }) => {
      params[data.target] = data.data
    })

    return params
  }

  get membersDetail(): MembersListItemType {
    return this.$store.getters['members/membersDetail']
  }

  get tableOrder() {
    const params = {}

    if (this.order && this.order.order) {
      params[this.order.prop] =
        this.order.order === 'descending' ? 'DESC' : 'ASC'
    }

    return params
  }

  setShowedCount(total: string): string | number {
    if (total) {
      return 10 * this.page > parseInt(total) ? total : 10 * this.page
    } else {
      return 0
    }
  }

  handleAddReportForm(): void {
    this.showAddReportsForm = !this.showAddReportsForm
  }

  handleShowSuccess(): void {
    this.addReportSuccess = !this.addReportSuccess
  }

  handleReportsError(): void {
    this.createReportsError = !this.createReportsError
  }

  handleTabClick(data: { id: number; tab: string }): void {
    this.filters = []
    this.page = 1
    this.selectedTab = data.tab
    this.order = {}
  }

  lazyDownload(exportData: TableExportType): void {
    const link = document.createElement('a')

    link.setAttribute('href', process.env.VUE_APP_BASE_URI + exportData.link)
    link.setAttribute('download', exportData.name)
    link.click()
    link.remove()
  }

  async handleExportOrders(): Promise<void> {
    switch (this.selectedTab) {
      case 'Заказы призов':
        await this.$store.dispatch('orders/getExportOrders', {
          filter: this.tableFilters,
          order: this.tableOrder,
        })
        if (
          this.ordersExport.type === 'error' ||
          this.ordersExport.type === 'queue'
        ) {
          Message.error(this.ordersExport.text)
        } else {
          this.lazyDownload(this.ordersExport)
        }
        break
      case 'Пополнение карт':
        await this.$store.dispatch('accruals/getExportAccruals', {
          filter: this.tableFilters,
          order: this.tableOrder,
        })
        if (
          this.accrualsExport.type === 'error' ||
          this.accrualsExport.type === 'queue'
        ) {
          Message.error(this.accrualsExport.text)
        } else {
          this.lazyDownload(this.accrualsExport)
        }
        break
      case 'Отчеты по заказам':
        await this.$store.dispatch('orderReports/getExportOrderReports', {
          filter: this.tableFilters,
          order: this.tableOrder,
        })

        if (
          this.orderReportsExport.type === 'error' ||
          this.orderReportsExport.type === 'queue'
        ) {
          Message.error(this.orderReportsExport.text)
        } else {
          this.lazyDownload(this.orderReportsExport)
        }
        break
    }
  }

  async handleShowMemberInfo(id: number): Promise<void> {
    this.loadingInfo = true
    this.showOrderDetail = false
    this.showAccrualDetail = false
    this.showOrderReportsDetail = false
    this.showMemberInfo = true

    await this.$store.dispatch('members/getMembersDetail', id)
    this.loadingInfo = false
  }

  async handleShowDetail(id: number): Promise<void> {
    this.loadingInfo = true
    this.showMemberInfo = false

    await this.$store.dispatch('members/getMembersDetail', id)

    switch (this.selectedTab) {
      case 'Заказы призов':
        this.showOrderDetail = true
        await this.$store.dispatch('orders/getOrdersDetail', id)
        break
      case 'Пополнение карт':
        this.showAccrualDetail = true
        await this.$store.dispatch('accruals/getAccrualsDetail', id)
        break
      case 'Отчеты по заказам':
        this.showOrderReportsDetail = true
        await this.$store.dispatch('orderReports/getOrderReportsDetail', id)
        break
    }

    this.loadingInfo = false
  }

  async handleChangePage(): Promise<void> {
    const tabs = document.querySelector('.orders__content') as HTMLElement
    tabs.scrollIntoView({ behavior: 'smooth' })

    const params = {
      filter: {},
      order: {},
      offset: 0,
    }
    params.filter = this.tableFilters
    params.order = this.tableOrder
    params.offset = (this.page - 1) * 10

    this.loading = true

    switch (this.selectedTab) {
      case 'Заказы призов':
        await this.$store.dispatch('orders/getOrdersList', params)
        break
      case 'Пополнение карт':
        await this.$store.dispatch('accruals/getAccrualsList', params)
        break
      case 'Отчеты по заказам':
        await this.$store.dispatch('orderReports/getOrderReportsList', params)
        break
    }

    this.loading = false
  }

  handleAddTableFilter(data: TableFilterType): void {
    this.page = 1

    let names = {}

    switch (this.selectedTab) {
      case 'Заказы призов':
        names = this.ordersFilterNames
        break
      case 'Пополнение карт':
        names = this.accrualsFilterNames
        break
      case 'Отчеты по заказам':
        names = this.reportsFilterNames
        break
    }

    this.isAnimationEnd = true

    const foundFilterIndex = this.filters.findIndex(
      (filter) => filter.target === data.target
    )

    if (foundFilterIndex === -1) {
      if (data.data === '') {
        return
      }

      this.filters.push({
        data: data.data,
        target: data.target,
        name: names[data.target],
      })
    } else {
      if (data.data === '') {
        this.handleDeleteFilter(foundFilterIndex)
      }

      this.filters.forEach((item) => {
        if (item.target === data.target) {
          this.handleDeleteFilter(foundFilterIndex)

          this.filters.push({
            data: data.data,
            target: data.target,
            name: names[data.target],
          })
        }
      })
    }

    this.isAnimationEnd = true
  }

  handleDeleteFilter(index: number): void {
    this.filters.splice(index, 1)
  }

  handleChangeSort(data: TableOrderType): void {
    this.order = data
    this.handleChangePage()
  }

  handleShowFilters(): void {
    this.showFilters = !this.showFilters
  }

  handleSelectFilters(data: TableFilterType[]): void {
    this.handleShowFilters()
    data.forEach((value) => {
      this.handleAddTableFilter(value)
    })

    this.isAnimationEnd = true
  }

  @Watch('filters')
  onFiltersChange(): void {
    this.handleChangePage()
  }

  @Watch('page')
  onPageChange(): void {
    this.handleChangePage()
  }

  async mounted(): Promise<void> {
    this.loading = true

    await this.$store.dispatch('orders/getOrdersList')

    this.loading = false
  }
}
