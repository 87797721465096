







































































import { Component, Vue, Model } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import SelectCommon from '@/components/common/SelectCommon.vue'
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'
import { UserResponseType } from '@/types/user'
import { AllVendorsType, VendorOptionsType } from '@/types/vendors'

export type ReportsCreateFormType = {
  REPORT_TYPE: null
  DATE_FROM: string
  DATE_TO: string
  NAME: string
  VENDOR_ID: string | null
  ALL_PARTNER_VENDOR: boolean
}

@Component({
  components: { DatePickerCommon, SelectCommon, InputCommon },
})
export default class reportsCreateForm extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  form: ReportsCreateFormType = {
    REPORT_TYPE: null,
    DATE_FROM: '',
    DATE_TO: '',
    NAME: '',
    VENDOR_ID: null,
    ALL_PARTNER_VENDOR: false,
  }

  typeOptions = [
    {
      label: 'Полный',
      value: 'FULL',
    },
    {
      label: 'Заказы',
      value: 'ORDER',
    },
    {
      label: 'Начисления',
      value: 'ACCRUAL',
    },
    {
      label: 'Партнерский счет',
      value: 'ORDER_P',
    },
  ]

  vendorOptions: VendorOptionsType[] = []

  get userData(): UserResponseType {
    return this.$store.getters['user/user']
  }

  get vendors(): AllVendorsType[] {
    return this.$store.getters['vendors/allVendors']
  }

  get orderCreateResponse(): boolean {
    return this.$store.getters['orderReports/successfulCreate']
  }

  clearForm(form: ReportsCreateFormType): void {
    for (const prop in form) {
      delete this.form[prop]
    }
  }

  handleAllPartnerCheckboxChange(): void {
    this.form.REPORT_TYPE = null
  }

  handleReportTypeChange(): void {
    this.form.ALL_PARTNER_VENDOR = false
  }

  async handleAddReport(): Promise<void> {
    if (this.userData.role !== 'admin') {
      this.form.VENDOR_ID = this.userData.vendor_id
    }

    await this.$store.dispatch('orderReports/orderReportsCreate', this.form)

    if (this.orderCreateResponse) {
      this.$emit('add-report')
      this.handleCloseDialog()
      this.clearForm(this.form)
    } else {
      this.$emit('open-dialog-reports-error')
    }
  }

  handleCloseDialog(): void {
    this.$emit('change', false)
  }

  async setVendors(): Promise<void> {
    this.vendors.forEach((vendor: AllVendorsType) => {
      this.vendorOptions.push({
        label: vendor.name,
        value: vendor.id,
      })
    })
  }

  async mounted(): Promise<void> {
    await this.$store.dispatch('user/getUserData')
    await this.$store.dispatch('vendors/getAllVendors')
    await this.setVendors()
  }
}
