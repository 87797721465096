













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Info extends Vue {
  @Prop({ type: String })
  title!: string

  @Prop({ type: String })
  iconName!: string
}
