




import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class EllipseCommon extends Vue {
  @Prop({ type: String })
  color!: string
}
